@font-face {
  font-family: 'Poppins-Regular', sans-serif;
  src: url('./fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins-Medium', sans-serif;
  src: url('./fonts/Poppins/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'Poppins-Light', sans-serif;
  src: url('./fonts/Poppins/Poppins-Light.ttf');
}

@font-face {
  font-family: 'Poppins-SemiBold', sans-serif;
  src: url('./fonts/Poppins/Poppins-SemiBold.ttf');
}

:root {
  --adm-font-family: 'Poppins-Regular', sans-serif !important;
}

body {
  margin: 0;
  font-family: 'Poppins-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}