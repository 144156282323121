.service-card {
  margin: 0.426667rem auto;
  width: 8.4rem;
  height: 3.066667rem;
  border-radius: 0.213333rem;
  background: #fff;
  box-shadow: 0 0.106667rem 0.266667rem rgba(0,0,0,.2);
  display: flex;
  align-items: center;
}

.service-card .img {
  display: block;
  width: 1.786667rem;
  height: 1.786667rem;
  margin-left: 0.613333rem;
}

.service-card .right-content {
  margin-left: 0.426667rem;
}

.service-card .right-content .right-content-title {
  text-align: left;
  font-family: Poppins-SemiBold sans-serif;
  font-size: 0.373333rem;
  font-weight: 600;
  line-height: 0.533333rem;
  letter-spacing: .03em;
  color: #000;
}

.service-card .right-content .right-content-desc {
  margin-top: 0.08rem;
  width: 4.426667rem;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.533333rem;
  color: #000;
}