.activate-warranty-orderinput {
  margin-top: 1.066667rem;
}

.activate-warranty-orderinput label {
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.373333rem;
  font-weight: 600;
  line-height: 0.533333rem;
  color: #000;
}

.activate-warranty-orderinput-inputbox {
  margin-top: 0.213333rem;
  display: flex;
  align-items: center;
}

.activate-warranty-orderinput-inputbox span {
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.373333rem;
  font-weight: normal;
  line-height: 0.666667rem;
}

.activate-warranty-orderinput-inputbox input {
  -webkit-appearance: none !important;
  appearance: button !important;
  -moz-appearance: button !important;
  border-radius: 0;
  -webkit-appearance: button !important;
  display: inline-block;
  text-align: center;
  flex: 1 1;
  border-radius: 0 !important;
  width: 0.183467rem;
  height: 0.666667rem;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.373333rem;
  font-weight: normal;
  line-height: 0.666667rem;
  color: #000;
  outline: none;
  border: none;
  border-bottom: 0.026667rem solid #000;
  margin: 0 0.08rem;
}

.activate-warranty-orderinput-inputbox input :focus {
  outline: none;
  border: none;
  border-bottom: 0.026667rem solid #000;
}

.activate-warranty-orderinput-warnning {
  display: flex;
  align-items: center;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.533333rem;
  color: #eb0000;
  margin-top: 0.346667rem;
  word-break: break-all;
}

.activate-warranty-orderinput-warnning img {
  margin-right: 0.133333rem;
  width: 0.373333rem;
  height: 0.373333rem;
}

.activate-warranty-orderinput-tips {
  display: flex;
  align-items: center;
  margin-top: 0.773333rem;
  justify-content: space-between;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.533333rem;
  letter-spacing: .03em;
  color: #0066c0;
}

.activate-warranty-orderinput-tips-left {
  display: flex;
  align-items: center;
}

.activate-warranty-orderinput-tips-left img {
  width: 0.32rem;
  height: 0.32rem;
  margin-right: 0.106667rem;
}

.activate-warranty-orderinput-tips-right {
  display: flex;
  align-items: center;
}

.activate-warranty-orderinput-tips-right img {
  margin-right: 0.106667rem;
  width: 0.32rem;
  height: 0.32rem;
}

.activate-warranty-orderinput-image {
  margin: 0.213333rem auto 0;
  display: block;
  width: 7.973333rem;
  height: 3.36rem;
  box-sizing: border-box;
}

.video-box {
  display: block;
}

.video-box .close-video {
  position: absolute;
  width: 0.533333rem;
  height: 0.533333rem;
  right: 0.533333rem;
  top: 0.533333rem;
  z-index: 9999;
}

.video-box .video {
  z-index: 999;
  display: block;
  margin: 1.066667rem auto 0;
  width: 7.333333rem;
  position: relative;
}