.box {
  position: relative;
  padding: 0 0.746667rem;
  height: 100%;
}

.box .user-info {
  margin-top: 0;
}

.box .user-info-item {
  height: 1.013333rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box .user-info-item label {
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.373333rem;
  font-weight: 600;
  color: #000;
}

.box .user-info-item input {
  text-align: left;
  width: 50%;
  font-family: Poppins-Light,sans-serif;
  font-size: 0.373333rem;
  font-weight: 300;
  color: #000;
  border: none;
  outline-style: none;
}

.box .user-info-item img {
  width: 0.266667rem;
  height: 0.266667rem;
  margin-right: 0.453333rem;
}

.box .user-info-rate-title {
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.373333rem;
  font-weight: 600;
  color: #000;
  display: block;
  line-height: 1.013333rem;
}

.box .user-info-rate-rating {
  margin-top: 0.4rem;
  margin-left: 0.213333rem;
  display: flex;
}

.box .user-info-rate-rating-stars {
  width: 6.133333rem;
  display: flex;
  height: 0.906667rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.box .user-info-rate-rating-stars-item {
  width: 0.906667rem;
  height: 0.906667rem;
}

.box .user-info-rate-rating-value {
  flex: auto;
  text-align: right;
  margin-right: 0.453333rem;
  font-family: Poppins-Bold,sans-serif;
  font-size: 0.426667rem;
  font-weight: bold;
  line-height: 0.906667rem;
  color: #000;
}

.box .user-info-comment {
  margin-top: 0.32rem;
}

.box .user-info-comment label {
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.373333rem;
  font-weight: 600;
  color: #000;
}

.box .user-info-comment-text-area {
  margin-top: 0.186667rem;
  height: 4.133333rem;
  border-radius: 0.213333rem;
  background: rgba(216,216,216,0);
  box-sizing: border-box;
  border: 0.026667rem solid #131921;
  padding: 0.213333rem;
}

.box .user-info-comment .input-tips {
  color: red;
  font-size: 0.32rem;
  float: right;
}

.box .confirm-button {
  margin-top: 0.533333rem;
}