.submit-address-box {
  padding: 0 0.746667rem;
}

.submit-address-box .top-view .title {
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.64rem;
  font-weight: 600;
  color: #000;
}

.submit-address-box .top-view .title :last-child {
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.373333rem;
  font-weight: normal;
  color: #000;
}

.submit-address-box .top-view .divider {
  margin-top: 0.133333rem;
  margin-bottom: 0.213333rem;
}

.submit-address-box .top-view .user-info {
  margin-top: 0;
}

.submit-address-box .top-view .user-info-item {
  height: 0.666667rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.submit-address-box .top-view .user-info-item label {
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.373333rem;
  font-weight: 600;
  color: #000;
}

.submit-address-box .top-view .user-info-item span {
  text-align: left;
  width: 50%;
  font-family: Poppins-Light,sans-serif;
  font-size: 0.373333rem;
  font-weight: 300;
  color: #000;
  border: none;
  outline-style: none;
  margin-right: 1.173333rem;
}

.submit-address-box .address-form .input-box {
  margin-top: 0.293333rem;
}

.submit-address-box .address-form .input-box label {
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.373333rem;
  font-weight: 600;
  line-height: 0.453333rem;
  color: #000;
}

.submit-address-box .address-form .input-box label span {
  color: #d91716;
}

.submit-address-box .address-form .input-box .input-item {
  display: block;
  width: 100%;
  margin-top: 0.133333rem;
  padding-left: 0.32rem;
  padding-right: 0.32rem;
  border-radius: 0.08rem;
  box-sizing: border-box;
  border: 0.026667rem solid #a2a6ac;
  height: 0.853333rem;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.453333rem;
  color: #000;
}

.submit-address-box .address-form .input-box .picker-result {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.submit-address-box .address-form .input-box .picker-result img {
  width: 0.293333rem;
  height: 0.16rem;
}

.submit-address-box .address-form .input-box .placeholder {
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.453333rem;
  color: #a1a1a1;
}

::-webkit-input-placeholder {
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.453333rem;
  color: #a1a1a1;
}

:-ms-input-placeholder {
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.453333rem;
  color: #a1a1a1;
}

::placeholder {
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.453333rem;
  color: #a1a1a1;
}

:-ms-input-placeholder {
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.453333rem;
  color: #a1a1a1;
}

::-ms-input-placeholder {
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.453333rem;
  color: #a1a1a1;
}

.confirm-button {
  margin: 0.8rem auto;
}