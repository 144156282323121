.home-guide .home-guide-title {
  text-align: left;
  height: 1.386667rem;
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.586667rem;
  font-weight: 600;
  line-height: 0.693333rem;
  letter-spacing: -0.05em;
  color: #000;
}

.home-guide .home-guide-desc {
  text-align: left;
  margin-top: 0.213333rem;
  padding: 0.08rem 0;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.373333rem;
  font-weight: normal;
  line-height: 0.533333rem;
  color: #000;
}

.home-guide .divider {
  margin-top: 0.133333rem;
  margin-bottom: 0;
  background-color: #d8d8d8;
}