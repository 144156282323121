.gift-cart-box {
  overflow-y: scroll;
  height: 100%;
  padding: 0 0.746667rem;
  background-color: #f7f8fc;
  position: relative;
}

.gift-cart-box .cart-list .cart-item {
  margin-top: 0.373333rem;
  width: 8.4rem;
  height: 3.493333rem;
  background: #fff;
  box-shadow: 0 0.106667rem 0.053333rem rgba(0,0,0,.05);
  display: flex;
  align-items: center;
  position: relative;
}

.gift-cart-box .cart-list .cart-item .delete-icon {
  position: absolute;
  width: 0.426667rem;
  height: 0.426667rem;
  right: 0.106667rem;
  top: 0.106667rem;
}

.gift-cart-box .cart-list .cart-item .gift-image {
  margin-left: 0.213333rem;
  width: 2.32rem;
  height: 2.32rem;
  border-radius: 0.133333rem;
}

.gift-cart-box .cart-list .cart-item .gift-info {
  margin-left: 0.293333rem;
}

.gift-cart-box .cart-list .cart-item .gift-info .gift-title {
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.373333rem;
  font-weight: 600;
  line-height: 0.373333rem;
  color: #000;
}

.gift-cart-box .cart-list .cart-item .gift-info .gift-spec {
  margin-top: 0.293333rem;
  padding-left: 0.24rem;
  width: 4.773333rem;
  height: 0.56rem;
  border-radius: 0.266667rem;
  background: #f4f4f4;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.56rem;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gift-cart-box .cart-list .cart-item .gift-info .gift-spec img {
  margin-right: 0.133333rem;
  width: 0.24rem;
  height: 0.133333rem;
}

.gift-cart-box .cart-list .cart-item .gift-info .gift-price {
  margin-top: 0.213333rem;
  font-family: Poppins-Medium,sans-serif;
  font-size: 0.373333rem;
  font-weight: 500;
  line-height: 0.666667rem;
  color: #f33a28;
}

.gift-cart-box .cart-list .cart-item .gift-info .take-up {
  float: right;
  color: #8c8c8c;
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.48rem;
  font-weight: 600;
}

.gift-cart-box .cart-list .cart-item .gift-info .number-edit-view {
  float: right;
  width: 1.866667rem;
  height: 0.613333rem;
  line-height: 0.533333rem;
  box-sizing: border-box;
  border-radius: 0.533333rem;
  display: flex;
  font-family: Poppins-Light,sans-serif;
  font-size: 0.32rem;
  font-weight: 300;
  color: #000;
}

.gift-cart-box .cart-list .cart-item .gift-info .number-edit-view span {
  text-align: center;
  flex: auto;
  border: .0.053333rem solid rgba(0,0,0,.6);
}

.gift-cart-box .cart-list .cart-item .gift-info .number-edit-view :first-child {
  border-radius: 0.266667rem 0 0 0.266667rem;
}

.gift-cart-box .cart-list .cart-item .gift-info .number-edit-view :nth-child(2) {
  border: none;
  display: inline-block;
  width: 0.533333rem;
  border-top: .0.053333rem solid rgba(0,0,0,.6);
  border-bottom: .0.053333rem solid rgba(0,0,0,.6);
  font-family: Poppins-Medium,sans-serif;
  font-size: 0.373333rem;
  font-weight: 500;
  color: #000;
}

.gift-cart-box .cart-list .cart-item .gift-info .number-edit-view :last-child {
  border-radius: 0 0.266667rem 0.266667rem 0;
}

.gift-cart-box .cart-list .cart-item-mask .gift-image {
  background-color: #8c8c8c;
}

.gift-cart-box .cart-list .cart-item-mask .gift-info {
  color: #8c8c8c;
}

.gift-cart-box .cart-list .cart-item-mask .gift-info .gift-title {
  color: #8c8c8c;
}

.gift-cart-box .cart-list .cart-item-mask .gift-info .gift-spec {
  color: #8c8c8c;
}

.gift-cart-box .cart-list .cart-item-mask .gift-info .gift-price {
  color: #8c8c8c;
}

.gift-cart-box .bottom-view {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1.733333rem;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gift-cart-box .bottom-view .price-view {
  height: 1.066667rem;
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.373333rem;
  font-weight: 600;
  line-height: 0.533333rem;
  letter-spacing: .03em;
  color: #000;
  width: 3.6rem;
  margin-left: 0.293333rem;
}

.gift-cart-box .bottom-view .price-view span {
  float: right;
}

.gift-cart-box .bottom-view .button-view {
  margin-right: 0.293333rem;
  width: 5.413333rem;
  height: 0.88rem;
  border-radius: 2rem;
  background: linear-gradient(180deg, #FFD54C 0%, #FFD54C 0%, #FFA013 100%, #FFA013 100%);
  display: flex;
  align-items: center;
}

.gift-cart-box .bottom-view .button-view .cart-view {
  display: inline-block;
  width: 2.714667rem;
  height: 0.88rem;
  line-height: 0.88rem;
  border-radius: 2rem;
  background: #fff;
  box-sizing: border-box;
  border: 0.026667rem solid #ffaf23;
  position: relative;
}

.gift-cart-box .bottom-view .button-view .cart-view .cart-icon {
  display: block;
  margin: 0.266667rem auto 0;
  width: 0.72rem;
  height: 0.454133rem;
}

.gift-cart-box .bottom-view .button-view .cart-view span {
  position: absolute;
  left: 0.08rem;
  top: -0.133333rem;
  right: 0;
  display: inline-block;
  text-align: center;
  font-family: Poppins-Bold,sans-serif;
  font-size: 0.32rem;
  font-weight: bold;
  line-height: 0.88rem;
  color: #e88405;
}

.gift-cart-box .bottom-view .button-view .ok-btn {
  flex: auto;
  font-family: Poppins-Medium,sans-serif;
  font-size: 0.48rem;
  font-weight: 500;
  line-height: 0.88rem;
  text-align: center;
  letter-spacing: .03em;
  color: #000;
}

.popup-gift-spec {
  border-radius: 0.266667rem;
  background: #fff;
  padding: 0.586667rem 0.586667rem 0;
  position: relative;
  overflow: hidden;
}

.popup-gift-spec .close-icon {
  right: 0.453333rem;
  top: 0.346667rem;
  position: absolute;
  width: 0.426667rem;
  height: 0.426667rem;
  z-index: 9999;
}

.popup-gift-spec .gift {
  display: flex;
  align-items: center;
}

.popup-gift-spec .gift .image {
  margin-left: 0.213333rem;
  width: 2.32rem;
  height: 2.32rem;
}

.popup-gift-spec .gift .gift-title-price {
  margin-left: 0.56rem;
}

.popup-gift-spec .gift .gift-title-price .title {
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.373333rem;
  font-weight: 600;
  line-height: 0.373333rem;
  color: #000;
}

.popup-gift-spec .gift .gift-title-price .price {
  margin-top: 0.106667rem;
  font-family: Poppins-Medium,sans-serif;
  font-size: 0.373333rem;
  font-weight: 500;
  line-height: 0.666667rem;
  color: #f33a28;
}

.popup-gift-spec .specs {
  margin-bottom: 2.666667rem;
}

.popup-gift-spec .specs .title {
  margin-top: 0.533333rem;
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.373333rem;
  font-weight: 600;
  line-height: 0.373333rem;
  color: #000;
}

.popup-gift-spec .specs .specs-list {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  margin-top: 0.133333rem;
}

.popup-gift-spec .specs .specs-list .item {
  margin-top: 0.346667rem;
  width: 4.213333rem;
  height: 0.666667rem;
  border-radius: 2.8rem;
  background: #f4f4f4;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.666667rem;
  color: #000;
  text-align: center;
}

.popup-gift-spec .specs .specs-list .item-selected {
  background: rgba(243,58,40,.15);
  box-sizing: border-box;
  border: 0.026667rem solid #f33a28;
  color: #f33a28;
}

.bottom-button-view {
  position: absolute;
  left: 0.293333rem;
  right: 0.293333rem;
  bottom: 0.986667rem;
  margin-right: 0.293333rem;
  height: 0.88rem;
  border-radius: 2rem;
  background: linear-gradient(180deg, #FFD54C 0%, #FFD54C 0%, #FFA013 100%, #FFA013 100%);
  display: flex;
  align-items: center;
}

.bottom-button-view .cart-view {
  flex: 1 1;
  display: inline-block;
  height: 0.88rem;
  line-height: 0.88rem;
  border-radius: 2rem;
  background: #fff;
  box-sizing: border-box;
  border: 0.026667rem solid #ffaf23;
  position: relative;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.373333rem;
  font-weight: normal;
  letter-spacing: .03em;
  color: #000;
  text-align: center;
}

.bottom-button-view .ok-btn {
  flex: 1 1;
  font-family: Poppins-Medium,sans-serif;
  font-size: 0.48rem;
  font-weight: 500;
  line-height: 0.88rem;
  text-align: center;
  letter-spacing: .03em;
  color: #000;
}