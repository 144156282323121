.activate-warranty-btn {
  height: 1.226667rem;
  line-height: 1.226667rem;
  border-radius: 2.72rem;
  background: linear-gradient(180deg, #FFD44B 0%, #FFD44B 0%, #FFA115 100%, #FFA115 100%);
  border: none;
  font-family: Poppins-Medium,sans-serif;
  font-size: 0.48rem;
  font-weight: 500;
  text-align: center;
  color: #000;
}

.activate-warranty-btn-absolute {
  position: absolute;
  display: block;
  bottom: 1.28rem;
  left: 0.8rem;
  right: 0.8rem;
}