.box {
  position: relative;
  padding: 0 0.746667rem;
  height: 100%;
  overflow-y: scroll;
}

.box .user-info {
  margin-top: 0;
}

.box .user-info-item {
  height: 1.013333rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box .user-info-item label {
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.373333rem;
  font-weight: 600;
  color: #000;
}

.box .user-info-item span {
  text-align: left;
  width: 50%;
  font-family: Poppins-Light,sans-serif;
  font-size: 0.373333rem;
  font-weight: 300;
  color: #000;
  border: none;
  outline-style: none;
  margin-right: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.box .user-info-rate-title {
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.373333rem;
  font-weight: 600;
  color: #000;
  display: block;
  line-height: 1.013333rem;
}

.box .user-info-rate-rating {
  margin-top: 0.4rem;
  margin-left: 0.213333rem;
  display: flex;
  position: relative;
}

.box .user-info-rate-rating-stars {
  width: 6.133333rem;
  display: flex;
  height: 0.906667rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.box .user-info-rate-rating-stars-item {
  position: relative;
}

.box .user-info-rate-rating-stars-item-price {
  top: 0;
  left: 0;
  position: absolute;
  display: inline-block;
  line-height: 1.013333rem;
  width: 0.906667rem;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  text-align: center;
  color: #000;
}

.box .user-info-rate-rating-stars-item img {
  width: 0.906667rem;
  height: 0.906667rem;
}

.box .user-info-rate-rating-gift {
  position: absolute;
  bottom: -0.266667rem;
  right: 0;
}

.box .user-info-rate-rating-gift span {
  position: absolute;
  width: 1.946667rem;
  text-align: center;
  font-family: Poppins-Bold,sans-serif;
  line-height: 2.186667rem;
  font-size: 0.426667rem;
  font-weight: 800;
  color: #000;
}

.box .user-info-rate-rating-gift img {
  width: 1.946667rem;
  height: 1.573333rem;
}

.box .user-choose-gift {
  margin-top: 0.266667rem;
  height: 3.066667rem;
  border-radius: 0.213333rem;
  background: #f8f9fc;
  box-shadow: 0 0.106667rem 0.053333rem rgba(0,0,0,.09);
  overflow: hidden;
}

.box .user-choose-gift-title {
  margin-top: 0.106667rem;
  margin-left: 0.213333rem;
  display: flex;
  align-items: center;
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.373333rem;
  font-weight: 600;
  line-height: 0.533333rem;
  letter-spacing: .03em;
  color: #000;
}

.box .user-choose-gift-title img {
  width: 0.773333rem;
  height: 0.693333rem;
}

.box .user-choose-gift-gifts {
  display: flex;
  justify-content: space-between;
  margin-left: 0.986667rem;
  margin-right: 0.4rem;
  margin-top: 0.16rem;
  height: 1.866667rem;
}

.box .user-choose-gift-gifts img {
  width: 1.2288rem;
  height: 1.44rem;
  border-radius: 0.293333rem;
  background: #fff;
  box-shadow: 0 0.106667rem 0.053333rem rgba(0,0,0,.09);
}

.box .user-gift-cart {
  margin-top: 0.346667rem;
  height: 3.36rem;
  border-radius: 0.213333rem;
  background: #f8f9fc;
  box-shadow: 0 0.106667rem 0.053333rem rgba(0,0,0,.09);
  overflow: hidden;
}

.box .user-gift-cart-title {
  margin-top: 0.186667rem;
  margin-left: 0.213333rem;
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.373333rem;
  font-weight: 600;
  line-height: 0.533333rem;
  letter-spacing: .03em;
  color: #000;
}

.box .user-gift-cart-title-icon {
  margin-top: 0.133333rem;
  float: left;
  position: relative;
  margin-right: 0.186667rem;
}

.box .user-gift-cart-title-icon span {
  position: absolute;
  left: 0.106667rem;
  right: 0;
  text-align: center;
  top: -0.24rem;
  font-family: Poppins-Bold,sans-serif;
  font-size: 0.32rem;
  font-weight: 800;
  line-height: 0.533333rem;
  color: #e88405;
}

.box .user-gift-cart-title-icon img {
  width: 0.72rem;
  height: 0.453333rem;
}

.box .user-gift-cart-box {
  display: flex;
  align-items: center;
}

.box .user-gift-cart-box-gifts {
  display: flex;
  justify-content: space-between;
  margin-right: 0.4rem;
  margin-top: 0.32rem;
  height: 1.6rem;
  width: 4.117333rem;
}

.box .user-gift-cart-box-gifts img {
  width: 1.2288rem;
  height: 1.44rem;
  border-radius: 0.293333rem;
  background: #fff;
  box-shadow: 0 0.106667rem 0.053333rem rgba(0,0,0,.09);
}

.box .user-gift-cart-box-infotext {
  width: 2.4rem;
  display: flex;
  flex-direction: column;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.533333rem;
  color: #000;
}

.box .user-gift-cart-box-infotext span {
  float: right;
  text-align: right;
  font-size: 0.373333rem;
  font-family: Poppins-SemiBold,sans-serif;
  font-weight: 600;
}

.box .user-gift-cart-box-infotext-bold {
  color: #de1716;
}

.box .user-gift-cart-box-tips {
  text-align: center;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.266667rem;
  color: #de1716;
  margin-top: 0.16rem;
}

.box .confirm-button {
  margin-top: 0.8rem;
}