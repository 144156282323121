.home {
  padding: 0 0.746667rem 0.533333rem;
}

.activate-warranty {
  margin: 0.373333rem auto 0;
  border-radius: 0.213333rem;
  background: #fff;
  box-shadow: 0 0.106667rem 0.266667rem rgba(0,0,0,.2);
  padding: 0.32rem 0.186667rem 0.533333rem;
}

.activate-warranty-title {
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.373333rem;
  font-weight: 600;
  line-height: 0.533333rem;
  color: #000;
}

.activate-warranty-desc {
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.533333rem;
  color: #000;
  margin-top: 0.133333rem;
}

.activate-warranty .activate-warranty-orderinput {
  margin-top: 1.066667rem;
}

.activate-warranty .activate-warranty-orderinput label {
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.373333rem;
  font-weight: 600;
  line-height: 0.533333rem;
  color: #000;
}

.activate-warranty .activate-warranty-orderinput-inputbox {
  margin-top: 0.213333rem;
  display: flex;
  align-items: center;
}

.activate-warranty .activate-warranty-orderinput-inputbox span {
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.373333rem;
  font-weight: normal;
  line-height: 0.666667rem;
}

.activate-warranty .activate-warranty-orderinput-inputbox input {
  display: inline-block;
  text-align: center;
  flex: 1 1;
  width: 0.183467rem;
  height: 0.666667rem;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.373333rem;
  font-weight: normal;
  line-height: 0.666667rem;
  color: #000;
  outline: none;
  border: none;
  border-bottom: 0.026667rem solid #000;
  margin: 0 0.08rem;
}

.activate-warranty .activate-warranty-orderinput-inputbox input :focus {
  outline: none;
  border: none;
  border-bottom: 0.026667rem solid #000;
}

.activate-warranty .activate-warranty-orderinput-warnning {
  display: flex;
  align-items: center;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.533333rem;
  color: #eb0000;
  margin-top: 0.346667rem;
}

.activate-warranty .activate-warranty-orderinput-warnning img {
  margin-right: 0.133333rem;
  width: 0.373333rem;
  height: 0.373333rem;
}

.activate-warranty .activate-warranty-orderinput-tips {
  display: flex;
  align-items: center;
  margin-top: 0.773333rem;
  justify-content: space-between;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.533333rem;
  letter-spacing: .03em;
  color: #0066c0;
}

.activate-warranty .activate-warranty-orderinput-tips-left {
  display: flex;
  align-items: center;
}

.activate-warranty .activate-warranty-orderinput-tips-left img {
  width: 0.32rem;
  height: 0.32rem;
  margin-right: 0.106667rem;
}

.activate-warranty .activate-warranty-orderinput-tips-right {
  display: flex;
  align-items: center;
}

.activate-warranty .activate-warranty-orderinput-tips-right img {
  margin-right: 0.106667rem;
  width: 0.32rem;
  height: 0.32rem;
}

.activate-warranty-submit-btn {
  display: block;
  margin: 0.746667rem auto 0;
  width: 6.746667rem;
  height: 0.986667rem;
  border-radius: 2rem;
  background: linear-gradient(180deg, #FFD54C 0%, #FFD54C 0%, #FFA013 100%, #FFA013 100%);
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.373333rem;
  font-weight: normal;
  line-height: 0.533333rem;
  letter-spacing: .03em;
  color: #000;
}