.app {
  position: relative;
  margin: 0 auto;
  max-width: 10rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-size: 0.48rem;
  justify-content: space-around;
  align-items: center;
}

.app .top {
  width: 100%;
  height: 1.333333rem;
  left: 0;
  top: 0;
  right: 0;
}

.app .top .search-input {
  float: left;
  display: block;
  width: 8rem;
  height: 0.773333rem;
  border-radius: 0.08rem;
  box-sizing: border-box;
  border: 0.026667rem solid #000;
  outline: none;
  padding-left: 0.266667rem;
  font-size: 0.32rem;
}

.app ::-webkit-scrollbar {
  display: none;
}

.app .body {
  width: 100%;
  flex: auto;
  overflow-x: hidden;
  overflow-y: scroll;
}

.app .bottom {
  width: 100%;
  height: 1.6rem;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  box-shadow: 0 0.08rem 0.426667rem rgba(0,0,0,.5);
}