.choose-gift-box {
  padding: 0 0.746667rem;
  background-color: #f7f8fc;
  overflow-y: scroll;
  height: 100%;
  position: relative;
}

.choose-gift-box .top-view {
  z-index: 999;
  background-color: #f7f8fc;
  position: absolute;
  right: 0.746667rem;
  left: 0.746667rem;
  height: 1.733333rem;
}

.choose-gift-box .top-view .order-options {
  margin-right: 0.773333rem;
  margin-top: 0.266667rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.choose-gift-box .top-view .order-options .item {
  font-family: Poppins-Medium,sans-serif;
  font-size: 0.373333rem;
  font-weight: 500;
  line-height: 0.533333rem;
  color: #000;
}

.choose-gift-box .top-view .order-options .item img {
  vertical-align: middle;
  width: 0.186667rem;
  height: 0.103733rem;
}

.choose-gift-box .top-view-gift-type-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.choose-gift-box .top-view-gift-type-options .item {
  width: 2.426667rem;
  height: 0.64rem;
  border-radius: 0.32rem;
  box-sizing: border-box;
  background-color: #fff;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.64rem;
  color: #000;
  text-align: center;
  margin-top: 0.186667rem;
}

.choose-gift-box .top-view-gift-type-options .item-selected {
  background: rgba(222,121,33,.1);
  box-sizing: border-box;
  border: 0.026667rem solid #aa5811;
  color: #de7921;
}

.choose-gift-box .top-view-gift-type-options .filter {
  width: 0.693333rem;
  height: 0.693333rem;
  display: flex;
  align-items: center;
}

.choose-gift-box .top-view-gift-type-options .filter span {
  width: 0.026667rem;
  height: 0.693333rem;
  border-left: 0.026667rem solid rgba(0,0,0,.13);
  box-shadow: 0 0.026667rem 0.053333rem rgba(0,0,0,.13);
}

.choose-gift-box .top-view-gift-type-options .filter img {
  margin-left: 0.2rem;
  width: 0.426667rem;
  height: 0.426667rem;
}

.choose-gift-box .gift-list {
  margin-bottom: 2rem;
  margin-top: 1.466667rem;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

.choose-gift-box .gift-list .gift-item {
  margin-top: 0.373333rem;
  width: 3.92rem;
  height: 4.72rem;
  border-radius: 0.053333rem;
  background: #fff;
  box-shadow: 0 0.106667rem 0.053333rem rgba(0,0,0,.05);
  position: relative;
}

.choose-gift-box .gift-list .gift-item .selected-icon {
  position: absolute;
  left: 0.266667rem;
  top: 0.213333rem;
  width: 0.4rem;
  height: 0.4rem;
}

.choose-gift-box .gift-list .gift-item .gift-image {
  height: 2.666667rem;
  width: auto;
  margin: 0 auto;
  display: block;
}

.choose-gift-box .gift-list .gift-item .gift-title {
  margin: 0.32rem 0.133333rem 0;
  font-family: Poppins-Medium,sans-serif;
  font-size: 0.32rem;
  font-weight: 500;
  line-height: 0.373333rem;
  color: #505050;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.choose-gift-box .gift-list .gift-item .gift-price {
  margin-left: 0.133333rem;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.666667rem;
  color: #f33a28;
}

.choose-gift-box .bottom-view {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1.733333rem;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.choose-gift-box .bottom-view .price-view {
  height: 1.066667rem;
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.373333rem;
  font-weight: 600;
  line-height: 0.533333rem;
  letter-spacing: .03em;
  color: #000;
  width: 3.6rem;
  margin-left: 0.293333rem;
}

.choose-gift-box .bottom-view .price-view span {
  float: right;
}

.choose-gift-box .bottom-view .button-view {
  margin-right: 0.293333rem;
  width: 5.413333rem;
  height: 0.88rem;
  border-radius: 2rem;
  background: linear-gradient(180deg, #FFD54C 0%, #FFD54C 0%, #FFA013 100%, #FFA013 100%);
  display: flex;
  align-items: center;
}

.choose-gift-box .bottom-view .button-view .cart-view {
  display: inline-block;
  width: 2.714667rem;
  height: 0.88rem;
  line-height: 0.88rem;
  border-radius: 2rem;
  background: #fff;
  box-sizing: border-box;
  border: 0.026667rem solid #ffaf23;
  position: relative;
}

.choose-gift-box .bottom-view .button-view .cart-view .cart-icon {
  display: block;
  margin: 0.266667rem auto 0;
  width: 0.72rem;
  height: 0.454133rem;
}

.choose-gift-box .bottom-view .button-view .cart-view span {
  position: absolute;
  left: 0.08rem;
  top: -0.133333rem;
  right: 0;
  display: inline-block;
  text-align: center;
  font-family: Poppins-Bold,sans-serif;
  font-size: 0.32rem;
  font-weight: bold;
  line-height: 0.88rem;
  color: #e88405;
}

.choose-gift-box .bottom-view .button-view .ok-btn {
  flex: auto;
  font-family: Poppins-Medium,sans-serif;
  font-size: 0.48rem;
  font-weight: 500;
  line-height: 0.88rem;
  text-align: center;
  letter-spacing: .03em;
  color: #000;
}

.popup-options {
  border-radius: 0.533333rem;
  min-height: 5.333333rem;
  background: #fff;
  box-shadow: 0 0.106667rem 0.266667rem rgba(0,0,0,.05);
  padding: 0.933333rem 0.426667rem 0;
}

.popup-options .options-title {
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.373333rem;
  font-weight: normal;
  line-height: 0.533333rem;
  color: #000;
}

.popup-options .type-options {
  margin-top: 0.48rem;
}

.popup-options .type-options .items {
  margin-top: 0.373333rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup-options .type-options .items span {
  text-align: center;
  width: 2.506667rem;
  height: 0.746667rem;
  border-radius: 0.533333rem;
  background: #f3f3f3;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.746667rem;
  color: #000;
}

.popup-options .price-options {
  margin-top: 0.48rem;
}

.popup-options .price-options .items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.373333rem;
}

.popup-options .price-options .items span {
  display: block;
  width: 0.533333rem;
  text-align: center;
}

.popup-options .price-options .items input {
  display: block;
  outline: none;
  width: 3.466667rem;
  height: 0.8rem;
  border-radius: 0.533333rem;
  background: #f3f3f3;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  text-align: center;
  border: none;
}

.bottom-button-view {
  position: absolute;
  left: 0.293333rem;
  right: 0.293333rem;
  bottom: 0.986667rem;
  margin-right: 0.293333rem;
  height: 0.88rem;
  border-radius: 2rem;
  background: linear-gradient(180deg, #FFD54C 0%, #FFD54C 0%, #FFA013 100%, #FFA013 100%);
  display: flex;
  align-items: center;
}

.bottom-button-view .cart-view {
  flex: 1 1;
  display: inline-block;
  height: 0.88rem;
  line-height: 0.88rem;
  border-radius: 2rem;
  background: #fff;
  box-sizing: border-box;
  border: 0.026667rem solid #ffaf23;
  position: relative;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.373333rem;
  font-weight: normal;
  letter-spacing: .03em;
  color: #000;
  text-align: center;
}

.bottom-button-view .ok-btn {
  flex: 1 1;
  font-family: Poppins-Medium,sans-serif;
  font-size: 0.48rem;
  font-weight: 500;
  line-height: 0.88rem;
  text-align: center;
  letter-spacing: .03em;
  color: #000;
}

.jump-guide-box {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -3.066667rem;
  margin-left: -4.106667rem;
  width: 8.213333rem;
  height: 6.133333rem;
  border-radius: 0.053333rem;
  background: #fff;
  box-sizing: border-box;
  border: 0.026667rem solid #9f9f9f;
}

.jump-guide-box .warning-title {
  display: flex;
  align-items: center;
  margin-left: 0.533333rem;
  margin-top: 0.533333rem;
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.48rem;
  font-weight: 600;
  line-height: 0.533333rem;
  color: #000;
  position: relative;
}

.jump-guide-box .warning-title img {
  margin-right: 0.346667rem;
  width: 0.613333rem;
  height: 0.613333rem;
}

.jump-guide-box .warning-desc {
  margin: 0.213333rem 0.453333rem auto;
  font-size: 0.373333rem;
  line-height: 0.533333rem;
  color: #000;
}

.jump-guide-box .warning-buttons {
  position: absolute;
  right: 0.586667rem;
  bottom: 0.4rem;
}

.jump-guide-box .warning-buttons .again-button {
  width: 1.893333rem;
  height: 0.613333rem;
  border-radius: 0.053333rem;
  box-sizing: border-box;
  border: 0.026667rem solid #000;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.453333rem;
  text-align: center;
  color: #000;
  margin-right: 0.32rem;
}

.jump-guide-box .warning-buttons .yes-button {
  outline: none;
  border: none;
  width: 1.626667rem;
  height: 0.613333rem;
  border-radius: 0.053333rem;
  background: linear-gradient(180deg, #FFD54C 0%, #FFD54C 0%, #FFA013 100%, #FFA013 100%);
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.453333rem;
  color: #000;
}