.tabbar {
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.tabbar .tabbar-item {
  position: relative;
  flex: 1 1;
}

.tabbar .tabbar-item .badge {
  position: absolute;
  left: 50%;
  top: -0.093333rem;
  margin-left: -0.066667rem;
  font-size: 0.32rem;
  font-weight: bold;
  color: #ffa013;
}

.tabbar .tabbar-item .icon {
  width: 0.533333rem;
  height: auto;
}

.tabbar .tabbar-item .title {
  display: block;
  font-family: "Poppins-Regular",sans-serif;
  font-size: 0.32rem;
  font-weight: 500;
  color: #000;
}

.tabbar .tabbar-item .title-selected {
  color: #ffa013;
}