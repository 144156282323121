.watch-video {
  padding: 0 0.746667rem;
  position: relative;
  height: 100%;
}

.watch-video-box {
  margin: 0.293333rem auto;
  height: 4.613333rem;
  box-sizing: border-box;
  position: relative;
  padding: 0;
}

.watch-video-box-playbtn {
  display: block;
  position: absolute;
  width: 1.333333rem;
  height: 1.333333rem;
  border-radius: 0.08rem;
  box-sizing: border-box;
  top: 1.6rem;
  left: 50%;
  margin-left: -0.666667rem;
  z-index: 1000;
}

.watch-video-box video {
  margin: 0;
  width: 100%;
  height: 4.613333rem;
}

.watch-video .activate-warranty-btn {
  position: absolute;
  display: block;
  bottom: 1.28rem;
  left: 0.8rem;
  right: 0.8rem;
  height: 1.226667rem;
  line-height: 1.226667rem;
  border-radius: 2.72rem;
  background: linear-gradient(180deg, #FFD44B 0%, #FFD44B 0%, #FFA115 100%, #FFA115 100%);
  border: none;
  font-family: Poppins-Medium,sans-serif;
  font-size: 0.48rem;
  font-weight: 500;
  text-align: center;
  color: #000;
}

.watch-video .confirm-button {
  margin-top: 1.333333rem;
}