.email-input {
  overflow: hidden;
  margin-top: 0.213333rem;
  min-height: 2.4rem;
  box-sizing: border-box;
  border-radius: 0.08rem;
  background: #fff;
  box-shadow: 0 0.106667rem 0.266667rem rgba(0,0,0,.2);
}

.email-input-title {
  margin: 0.186667rem 0.293333rem 0;
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.373333rem;
  font-weight: 600;
  line-height: 0.533333rem;
  color: #000;
}

.email-input-email {
  margin: 0.106667rem 0.293333rem 0;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.533333rem;
  color: #000;
}

.email-input-input-box {
  position: relative;
  margin: 0.266667rem 0.293333rem 0.266667rem;
}

.email-input-input-box input {
  display: block;
  height: 0.853333rem;
  line-height: 0.853333rem;
  padding-left: 0.32rem;
  font-size: 0.32rem;
  font-weight: normal;
  font-family: Poppins-Regular,sans-serif;
  border-radius: 0.133333rem;
  box-sizing: border-box;
  border: 0.026667rem solid #000;
}

.email-input-input-box button {
  position: absolute;
  right: 0.026667rem;
  top: 0.026667rem;
  bottom: 0.026667rem;
  width: 1.626667rem;
  border: none;
  border-radius: 0 0.133333rem 0.133333rem 0;
  background: linear-gradient(180deg, #FFD54C 0%, #FFD54C 0%, #FFA013 100%, #FFA013 100%);
  text-align: center;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  letter-spacing: .03em;
  color: #000;
}

.email-input ::-webkit-input-placeholder {
  color: #a1a1a1;
  opacity: 1;
}

.email-input :-ms-input-placeholder {
  color: #a1a1a1;
  opacity: 1;
}

.email-input ::placeholder {
  color: #a1a1a1;
  opacity: 1;
}

.email-input :-ms-input-placeholder {
  color: #a1a1a1;
}

.email-input ::-ms-input-placeholder {
  color: #a1a1a1;
}