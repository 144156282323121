.activate-1-box {
  position: relative;
  padding: 0 0.746667rem;
  height: 100%;
}

.activate-1-box .goods-info {
  margin: 0.373333rem auto 0;
  height: 1.76rem;
  border-radius: 0.16rem;
  background: #fff;
  box-sizing: border-box;
  border: 0.026667rem solid #8f8f8f;
  display: flex;
  align-items: center;
}

.activate-1-box .goods-info-image-box {
  padding: 0.08rem 0;
  width: 3.6rem;
  height: 100%;
  border-right: 0.026667rem solid #8f8f8f;
  box-sizing: border-box;
}

.activate-1-box .goods-info-image-box-img {
  height: 1.6rem;
  width: auto;
  display: block;
  margin: 0 auto;
}

.activate-1-box .goods-info-msg {
  margin-left: 0.64rem;
  margin-right: 0.48rem;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 0.533333rem;
  flex: auto;
  color: #000;
}

.activate-1-box .goods-info-msg span {
  float: right;
  text-align: right;
}

.activate-1-box .info-input {
  margin-top: 0.133333rem;
}

.activate-1-box .info-input-box {
  margin-top: 0.506667rem;
}

.activate-1-box .info-input-box label {
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.373333rem;
  font-weight: 600;
  line-height: 0.533333rem;
  color: #000;
}

.activate-1-box .info-input-box input {
  margin-top: 0.213333rem;
  width: 100%;
  display: block;
  padding-left: 0.853333rem;
  height: 1.253333rem;
  border-radius: 0.16rem;
  box-sizing: border-box;
  border: 0.026667rem solid #a2a6ac;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.373333rem;
  font-weight: normal;
}

.activate-1-box .info-input-box .input-tips {
  color: red;
  font-size: 0.32rem;
  float: right;
}

.activate-1-box .confirm-button {
  margin-top: 1.066667rem;
}