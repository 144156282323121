.switch-language {
  display: flex;
  align-items: center;
  float: right;
}

.switch-language .national-flag {
  width: 0.426667rem;
  height: 0.32rem;
}

.switch-language .language {
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 1.333333rem;
  color: #000;
  margin: 0 0.16rem;
}

.switch-language .right-arrow {
  height: 0.293333rem;
  width: 0.186667rem;
}

.switch-language .down-arrow {
  height: 0.186667rem;
  width: 0.293333rem;
}

.dropdown {
  position: absolute;
  right: 0.133333rem;
  top: 1.066667rem;
  width: 2.773333rem;
  height: 6rem;
  border-radius: 0.08rem;
  background: #fff;
  box-shadow: 0 0.106667rem 0.266667rem rgba(0,0,0,.2);
  z-index: 1000;
}

.dropdown ul {
  padding: 0;
  margin: 0;
}

.dropdown ul li {
  padding: 0;
  margin: 0;
  height: 1rem;
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-left: 0.453333rem;
}

.dropdown ul li .d-national-flag {
  width: 0.426667rem;
  height: 0.32rem;
}

.dropdown ul li .d-language {
  text-align: center;
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 1.333333rem;
  color: #000;
  margin: 0 0.16rem;
}