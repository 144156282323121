.finish-success-box {
  padding: 0 0.746667rem;
}

.finish-success-box .image {
  display: block;
  margin: 4rem auto 0;
  width: 1.866667rem;
  height: 1.866667rem;
}

.finish-success-box .title {
  margin-top: 0.613333rem;
  text-align: center;
  font-family: Poppins-SemiBold,sans-serif;
  font-size: 0.586667rem;
  font-weight: 600;
  line-height: 0.8rem;
  color: #000;
}

.finish-success-box .title span {
  display: block;
}

.finish-success-box .desc {
  margin-top: 0.48rem;
  font-family: Poppins-Regular,sans-serif;
  font-size: 0.373333rem;
  font-weight: normal;
  line-height: 0.533333rem;
  text-align: center;
  color: #000;
}

.finish-success-box .confirm-button {
  margin-top: 1.333333rem;
}